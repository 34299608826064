// extracted by mini-css-extract-plugin
export var icon = "Icon-module--icon--600d8";
export var icon_position_end = "Icon-module--icon_position_end--87d6b";
export var icon_position_start = "Icon-module--icon_position_start--ac54a";
export var label = "Icon-module--label--3b38a";
export var large = "Icon-module--large--d9b8b";
export var medium = "Icon-module--medium--23a78";
export var root = "Icon-module--root--cd36a";
export var rotate90 = "Icon-module--rotate90--d0b64";
export var skeleton = "Icon-module--skeleton--d41a1";
export var small = "Icon-module--small--a9dce";
export var spin = "Icon-module--spin--a72f8";