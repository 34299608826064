// extracted by mini-css-extract-plugin
export var container = "Header-module--container--1e893";
export var desktop = "Header-module--desktop--0172c";
export var end = "Header-module--end--aab6e";
export var hamburger = "Header-module--hamburger--a07ed";
export var isHidden = "Header-module--isHidden--3263c";
export var isVisible = "Header-module--isVisible--ff2fe";
export var logo = "Header-module--logo--c8f0b";
export var logo__img = "Header-module--logo__img--597e0";
export var middle = "Header-module--middle--6454a";
export var mobile = "Header-module--mobile--57ccf";
export var navDown = "Header-module--navDown--839e4";
export var navUp = "Header-module--navUp--adf5d";
export var navigation = "Header-module--navigation--eec24";
export var navigation__list = "Header-module--navigation__list--1f589";
export var navigation__list__item = "Header-module--navigation__list__item--f0ecc";
export var navigation__list__item__link = "Header-module--navigation__list__item__link--d29b3";
export var navigation__overlay = "Header-module--navigation__overlay--3440c";
export var onTop = "Header-module--onTop--fe6d7";
export var proposalButton = "Header-module--proposalButton--926f1";
export var proposalButton__navigation = "Header-module--proposalButton__navigation--0d909";
export var root = "Header-module--root--b3d62";
export var start = "Header-module--start--e7d53";
export var sticky = "Header-module--sticky--472aa";