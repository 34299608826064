/**
 * Layouts -> Wrap
 */

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { root } from './Wrap.module.css';

const Wrap = forwardRef(({ children, className, hasPaddingTop, template, ...rest }, ref) => (
  <div
    id="wrapper"
    ref={ref}
    className={classnames(root, { [`template-${template}`]: template }, className)}
    {...rest}
  >
    {children}
  </div>
));

Wrap.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  hasPaddingTop: PropTypes.bool,
  template: PropTypes.string,
};

export default Wrap;
