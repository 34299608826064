/**
 * Helpers -> Scroll to element
 */

const scrollToElement = (idOrElement = '', updateHash = false, scrollOffset = 50) => {
  const el = typeof idOrElement === 'string' ? document.getElementById(idOrElement) : idOrElement;

  if (el) {
    if (updateHash && typeof idOrElement === 'string') {
      window.location.hash = idOrElement;
    }

    const element = el;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - scrollOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }
};

export default scrollToElement;
