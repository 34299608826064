/**
 * Components -> Heading
 * @missing-prop-types
 */

import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as css from './Heading.module.css';

const Heading = forwardRef(
  (
    {
      center,
      children,
      className,
      color,
      element: Element,
      font,
      lineHeight,
      marginBottom,
      marginTop,
      removeLineBreakOnMobile,
      responsive,
      size,
      text,
      upperCase,
      weight,
      italic,
      ...rest
    },
    ref,
  ) => {
    const classes = useMemo(
      () =>
        classnames(
          css.root,
          { [css.responsive]: responsive },
          { [css.center]: center },
          { [css.removeLineBreakOnMobile]: removeLineBreakOnMobile },
          css[`size_${size}`],
          css[`font_${font}`],
          css[`color_${color}`],
          css[`line_height_${lineHeight}`],
          css[`weight_${weight ? weight.replace('-', '_') : ''}`],
          css[`margin_bottom_${marginBottom}`],
          css[`margin_top_${marginTop}`],
          { [css.upperCase]: upperCase },
          { [css.italic]: italic },
          className,
        ),
      [
        responsive,
        center,
        removeLineBreakOnMobile,
        size,
        font,
        color,
        lineHeight,
        weight,
        marginBottom,
        marginTop,
        upperCase,
        className,
      ],
    );

    const dangerouslySetInnerHTML = text ? { dangerouslySetInnerHTML: { __html: text } } : {};

    return (
      <Element {...rest} ref={ref} className={classes} {...dangerouslySetInnerHTML}>
        {children}
      </Element>
    );
  },
);

Heading.defaultProps = {
  element: 'div',
  font: 'heading',
  responsive: false,
  upperCase: false,
  weight: 'regular',
  removeLineBreakOnMobile: true,
};

Heading.propTypes = {
  center: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  element: PropTypes.any,
  font: PropTypes.string,
  lineHeight: PropTypes.string,
  marginBottom: PropTypes.number,
  marginTop: PropTypes.number,
  removeLineBreakOnMobile: PropTypes.bool,
  responsive: PropTypes.bool,
  size: PropTypes.number,
  text: PropTypes.string,
  upperCase: PropTypes.bool,
  weight: PropTypes.string,
  italic: PropTypes.bool,
};

export default Heading;
