// extracted by mini-css-extract-plugin
export var UNgoals = "Footer-module--UNgoals--8ea83";
export var UNgoals__item = "Footer-module--UNgoals__item--b4810";
export var bottom = "Footer-module--bottom--90149";
export var bottom__container = "Footer-module--bottom__container--9a047";
export var bottom__end = "Footer-module--bottom__end--916be";
export var bottom__end__socials = "Footer-module--bottom__end__socials--3beff";
export var bottom__end__text = "Footer-module--bottom__end__text--18273";
export var bottom__nav = "Footer-module--bottom__nav--03b88";
export var bottom__start = "Footer-module--bottom__start--fc1d2";
export var bottom__start__text = "Footer-module--bottom__start__text--d2ddf";
export var columns = "Footer-module--columns--8826d";
export var contactInfo = "Footer-module--contactInfo--fe07a";
export var description = "Footer-module--description--1afc5";
export var end = "Footer-module--end--87131";
export var inner = "Footer-module--inner--e486c";
export var logo = "Footer-module--logo--aac0e";
export var nav = "Footer-module--nav--ddc60";
export var nav__heading = "Footer-module--nav__heading--388a1";
export var nav__item = "Footer-module--nav__item--da8ed";
export var nav__list = "Footer-module--nav__list--14e73";
export var root = "Footer-module--root--931fb";
export var start = "Footer-module--start--8c340";
export var textBelowContact = "Footer-module--textBelowContact--61e18";
export var top = "Footer-module--top--47ef1";