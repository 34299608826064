/**
 * Components -> Link
 */

'use client';

import React, { forwardRef, useContext } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {
  darkLinkStyle,
  defaultLinkStyle,
  opacityLinkStyle,
  lightLinkStyle,
} from '@styles/shared/link.module.css';
import useShake from '@utils/hooks/useShake';
import { Link as RouterLink } from 'gatsby';
import AppContext from '../../layouts/AppContext';
import * as css from './Link.module.css';

const APPEARANCES = {
  default: defaultLinkStyle,
  opacity: opacityLinkStyle,
  dark: darkLinkStyle,
  light: lightLinkStyle,
};

const Link = forwardRef(
  (
    {
      bold,
      className,
      disableProgressBar = false,
      to: toProp,
      href: hrefProp,
      target,
      children,
      appearance,
      element: ElementProp,
      onClick,
      shakeOnCurrentPage,
      ...rest
    },
    ref,
  ) => {
    const { className: shakeClass, shake } = useShake(true);
    const { startPageProgressBar } = useContext(AppContext);

    const props = {
      to: undefined,
      href: undefined,
    };

    let Element = ElementProp;

    if (
      toProp ||
      (target !== '_blank' &&
        hrefProp &&
        (hrefProp.includes('://allset-industries.com') ||
          hrefProp.includes('www.allset-industries.com')))
    ) {
      Element = RouterLink;
      props.to =
        toProp ||
        hrefProp
          .replace('https://allset-industries.com', '')
          .replace('https://wp.allset-industries.com', '');
    } else if (hrefProp) {
      props.href = hrefProp;
    }

    const handleOnClick = (e) => {
      if (shakeOnCurrentPage && window.location.pathname === props.to) {
        e.preventDefault();
        shake();
      }

      if (onClick) {
        onClick(e);
      }

      if (props.to && !disableProgressBar) {
        startPageProgressBar(props.to);
      }
    };

    return (
      <Element
        ref={ref}
        target={target}
        rel={target === '_blank' ? 'noreferrer' : undefined}
        className={classnames(
          shakeClass,
          { [css.bold]: bold },
          { [APPEARANCES[appearance]]: appearance && APPEARANCES[appearance] },
          className,
        )}
        onClick={handleOnClick}
        {...props}
        {...rest}
      >
        {children}
      </Element>
    );
  },
);

Link.propTypes = {
  appearance: PropTypes.oneOf(['default'].concat(Object.keys(APPEARANCES))),
  bold: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  disableProgressBar: PropTypes.bool,
  element: PropTypes.any,
  href: PropTypes.string,
  onClick: PropTypes.func,
  shakeOnCurrentPage: PropTypes.bool,
  target: PropTypes.string,
  to: PropTypes.string,
};

Link.defaultProps = {
  disableProgressBar: false,
  element: 'a',
};

export default Link;
