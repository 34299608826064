/**
 * CookieBanner (lazy loaded)
 */

import React, { lazy, Suspense } from 'react';
import useUserHasInteracted from '@utils/hooks/useUserHasInteracted';

const LazyCookieBanner = lazy(() => import('./CookieBanner'));

const CookieBanner = (props) => {
  const userDidInteract = useUserHasInteracted();

  if (!userDidInteract) {
    return null;
  }

  return (
    <Suspense fallback={null}>
      <LazyCookieBanner {...props} />
    </Suspense>
  );
};

export default CookieBanner;
