/**
 * Gatsby Browser
 */

require('@styles/app.css');

/**
 * Used for Google Tagmanager
 */
// module.exports.onRouteUpdate = () => {
//   // wrap inside a timeout to ensure the title has properly been changed
//   setTimeout(() => {
//     // Track GA pageview
//     if (Array.isArray(window.dataLayer)) {
//       window.dataLayer.push({ event: 'gatsby-route-change' });
//     }
//   }, 50);
// };

module.exports.onRouteUpdate = ({ location }) => {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }

  const pagePath = location ? location.pathname + location.search + location.hash : undefined;

  setTimeout(() => {
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'page_view', { page_path: pagePath });
    }
  }, 100);
};

module.exports.onClientEntry = async () => {
  // Remove Gray Highlight When Tapping Links in Mobile Safari,
  // and then to allow :active styles to work in your CSS on a page in Mobile Safari:
  // document.addEventListener('touchstart', () => {}, true);

  // /**
  //  * Lazy load google tag manager
  //  */
  // if (isProduction && process.env.GATSBY_GOOGLE_TAGMANAGER_ID) {
  //   const initGTM = () => {
  //     if (window.gtmDidInit) {
  //       return;
  //     }

  //     const script = document.createElement('script');
  //     script.type = 'text/javascript';
  //     script.async = true;
  //     script.onload = () => {
  //       window.dataLayer = window.dataLayer || [];
  //       window.dataLayer.push({
  //         event: 'gtm.js',
  //         'gtm.start': new Date().getTime(),
  //         'gtm.uniqueEventId': 0,
  //       });
  //     }; // this part ensures PageViews is always tracked
  //     script.src = `https://www.googletagmanager.com/gtm.js?id=${process.env.GATSBY_GOOGLE_TAGMANAGER_ID}`;

  //     document.head.appendChild(script);

  //     window.gtmDidInit = true; // flag to ensure script does not get added to DOM more than once.
  //   };

  //   const initGTMOnEvent = () => {
  //     initGTM();
  //     document.removeEventListener('click', initGTMOnEvent);
  //     document.removeEventListener('scroll', initGTMOnEvent);
  //     document.removeEventListener('mousemove', initGTMOnEvent);
  //     document.removeEventListener('touchstart', initGTMOnEvent);
  //   };

  //   // document.addEventListener('load', () => {
  //   // document.addEventListener('DOMContentLoaded', () => {
  //   const isPageInsights = navigator.userAgent.includes('Chrome-Lighthouse');

  //   if (!isPageInsights) {
  //     document.onreadystatechange = function ready() {
  //       if (document.readyState !== 'loading') setTimeout(initGTM, 1500);
  //     };

  //     document.addEventListener('click', initGTMOnEvent);
  //     document.addEventListener('scroll', initGTMOnEvent);
  //     document.addEventListener('mousemove', initGTMOnEvent);
  //     document.addEventListener('touchstart', initGTMOnEvent);
  //   }
  // }

  // InteractionObserver polyfill (needed for some browsers for gatsby-image to work properly)
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`);
  }
};
