/**
 * useSiteData: Get global site data
 */

import { graphql, useStaticQuery } from 'gatsby';
import { useMemo } from 'react';

const useSiteData = () => {
  const data = useStaticQuery(graphql`
    {
      wp {
        generalSettings {
          title
        }
        general {
          general {
            phone
            email
            address
            googleMapsLink
            socialMedia {
              label
              link
              platform
            }
          }
        }
        callToAction {
          cta {
            buttonLabel
            title
          }
        }
        overlay {
          overlay {
            overlayTitle
            overlayText
            overlaySuccessTitle
            overlaySuccessSub
            overlayServices {
              name
            }
          }
        }
        footer {
          footer {
            footerSocialMediaText
            footerTextBelowContactInfo
            footerDescription
            footerMenu {
              heading
              menuItems {
                label
                page {
                  ... on WpPage {
                    uri
                    title
                  }
                }
              }
              page {
                ... on WpPage {
                  uri
                  title
                }
              }
            }
          }
        }
      }
    }
  `);

  return useMemo(() => {
    const {
      generalSettings: { title: siteName },
      callToAction: { cta },
      overlay: {
        overlay: {
          overlayTitle,
          overlayText,
          overlaySuccessTitle,
          overlaySuccessSub,
          overlayServices,
        },
      },
      footer: {
        footer: {
          footerDescription,
          footerMenu,
          footerTextBelowContactInfo,
          footerSocialMediaText,
        },
      },
      general: {
        general: { phone, email, address, googleMapsLink, socialMedia: socials },
      },
    } = data.wp;

    return {
      siteName,
      phone,
      email,
      socials,
      address,
      googleMapsLink,
      overlay: {
        title: overlayTitle,
        text: overlayText,
        successTitle: overlaySuccessTitle,
        successSub: overlaySuccessSub,
        services: overlayServices,
      },
      footer: {
        description: footerDescription,
        menu: footerMenu,
        textBelowContact: footerTextBelowContactInfo,
        socialText: footerSocialMediaText,
      },
      cta,
    };
  }, []);
};

export default useSiteData;
