/**
 * Header -> NavItem
 */

'use client';

import React, { useCallback, useState, useRef, memo, useEffect, useMemo } from 'react';
import classnames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { darkLinkStyle } from '@styles/shared/link.module.css';
import { ReactComponent as caretDown } from '@components/Icon/icons/caret-down.svg';
import { useLocation } from '@reach/router';

import Button from '@components/Button';
import Heading from '@components/Heading';
import Icon from '@components/Icon';
import Link from '@components/Link';

import * as css from './NavItem.module.css';

const MOBILE_WIDTH = 1020;
const isMobileNav = () => window.innerWidth <= MOBILE_WIDTH;

const SubMenu = memo(
  ({ data, visible, onMouseEnter, onMouseLeave, onItemClick, id, onToggle, menuItemRef }) => {
    const ref = useRef(null);
    const innerRef = useRef();

    const [menuStyle, setMenuStyle] = useState({
      height: visible ? null : 0,
    });

    useEffect(() => {
      const handleClickOutside = ({ target }) => {
        if (isMobileNav() || ref.current.contains(target) || menuItemRef.current.contains(target)) {
          return;
        }

        onToggle();
      };

      if (visible) {
        document.addEventListener('click', handleClickOutside);
      } else {
        document.removeEventListener('click', handleClickOutside);
      }

      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }, [visible]);

    if (!Array.isArray(data) || !data.length) {
      return null;
    }

    const renderMenu = useCallback((item) => {
      // Format data coming from categories, pages etc. to use the same format
      const pages = item.pages.map((page) => {
        if (page.page) {
          return {
            uri: page.page.uri,
            label: page.alternativeTitle || page.page.title,
            id: page.page.id,
          };
        }

        return {
          uri: page.uri,
          label: page.name || page.label,
          id: page.id,
        };
      });

      return (
        <li key={item.title} className={css.subMenu__list__item}>
          {item.title && (
            <Heading
              weight="bold"
              upperCase
              element="h3"
              className={css.subMenu__list__item__title}
            >
              {item.title}
            </Heading>
          )}
          {pages.length ? (
            <ul>
              {pages
                .filter(({ uri }) => !!uri)
                .map((page) => (
                  <li className={css.subMenu__list__item__pages__page} key={page.id}>
                    <Link
                      className={classnames(
                        darkLinkStyle,
                        css.subMenu__list__item__pages__page__link,
                      )}
                      to={page.uri}
                      activeClassName={css.subMenu__list__item__pages__page__active}
                      onClick={() => onItemClick(page.label)}
                      partiallyActive
                    >
                      {page.label}
                    </Link>
                  </li>
                ))}
            </ul>
          ) : null}
        </li>
      );
    }, []);

    const renderSubMenu = useMemo(
      () => <ul className={css.subMenu__list}>{data.map(renderMenu)}</ul>,
      [data, renderMenu],
    );

    const setHeight = () => setMenuStyle({ height: `${innerRef.current.scrollHeight}px` });
    const onEnter = setHeight;
    const onEntered = () => setMenuStyle(null);
    const onExit = setHeight;
    const onExited = () => setMenuStyle({ height: 0 });

    return (
      <CSSTransition
        in={visible}
        timeout={200}
        unmountOnExit
        classNames={{
          enter: css.enter,
          enterActive: css.enterActive,
          exit: css.exit,
          exitActive: css.exitActive,
        }}
        onEnter={onEnter}
        onEntered={onEntered}
        onExit={onExit}
        onExited={onExited}
      >
        <div
          style={menuStyle}
          key={id}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          className={css.subMenu}
          ref={ref}
        >
          <div ref={innerRef}>{renderSubMenu}</div>
        </div>
      </CSSTransition>
    );
  },
  (prev, next) => prev.visible === next.visible,
);

const NavItem = ({ id, onFocus, onBlur, onClick, label, path, childMenu, attachSubMenuToItem }) => {
  const router = useLocation();
  const ref = useRef();
  const timeout = useRef(null);
  const [subMenuVisible, setSubMenuVisible] = useState(false);

  // Open sub-menu on mobile on mount if current page is a child page
  useEffect(() => {
    const shouldOpenSub =
      router.pathname.split('/').filter(Boolean)[0] === path.split('/').filter(Boolean)[0];

    if (isMobileNav() && shouldOpenSub && !subMenuVisible) {
      setSubMenuVisible(true);
    }
  }, [router.pathname]);

  const closeSubMenu = useCallback(() => {
    setSubMenuVisible(false);
  });

  const handleClick = (_label) => {
    clearTimeout(timeout.current);
    if (!isMobileNav()) {
      closeSubMenu();
    }
    onClick(_label);
  };

  const handleMouseEnter = () => {
    if (isMobileNav()) {
      return;
    }

    clearTimeout(timeout.current);
    if (!subMenuVisible) {
      timeout.current = setTimeout(() => {
        setSubMenuVisible(true);
      }, 200);
    }
  };

  const handleMouseLeave = () => {
    if (isMobileNav()) {
      return;
    }

    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      closeSubMenu();
    }, 200);
  };

  const caretIcon = (
    <Icon
      icon={caretDown}
      className={classnames(css.caretIcon, {
        [css.open]: subMenuVisible,
      })}
    />
  );

  const isActive = ({ isCurrent, isPartiallyCurrent }) =>
    isCurrent || (isPartiallyCurrent && childMenu && path !== '/')
      ? { className: classnames(css.item__link, css.active) }
      : { className: css.item__link };

  return (
    <li
      key={id}
      className={classnames(css.item, { [css.attachSubMenuToItem]: attachSubMenuToItem })}
      ref={ref}
    >
      <div className={css.item__inner}>
        <Link
          onMouseEnter={childMenu ? handleMouseEnter : undefined}
          onMouseLeave={childMenu ? handleMouseLeave : undefined}
          className={css.item__link}
          to={path}
          onFocus={onBlur}
          onBlur={onFocus}
          onClick={() => handleClick(label)}
          getProps={isActive}
          shakeOnCurrentPage
        >
          <span className={css.item__link__label}>{label}</span>
        </Link>
        {!!childMenu && (
          <Button
            onClick={() => setSubMenuVisible(!subMenuVisible)}
            size="small"
            appearance="icon"
            className={css.item__subMenu__toggle}
            aria-label={subMenuVisible ? `Expand ${label} sub-menu` : `Collapse ${label} sub-menu`}
          >
            {caretIcon}
          </Button>
        )}
      </div>
      {childMenu && (
        <SubMenu
          id={`${id}-sub-menu`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onItemClick={handleClick}
          onToggle={closeSubMenu}
          menuItemRef={ref}
          visible={subMenuVisible}
          data={childMenu}
        />
      )}
    </li>
  );
};

export default memo(NavItem, () => true);
