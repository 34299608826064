// extracted by mini-css-extract-plugin
export var center = "Heading-module--center--d965f";
export var color_default = "Heading-module--color_default--9705a";
export var color_default_inverse = "Heading-module--color_default_inverse--c19a4";
export var color_gray_1 = "Heading-module--color_gray_1--c345e";
export var color_gray_2 = "Heading-module--color_gray_2--e65d0";
export var color_gray_3 = "Heading-module--color_gray_3--d0e56";
export var color_gray_4 = "Heading-module--color_gray_4--9c599";
export var color_gray_5 = "Heading-module--color_gray_5--6cf83";
export var color_text_secondary = "Heading-module--color_text_secondary--49739";
export var font_heading = "Heading-module--font_heading--bc3b4";
export var font_regular = "Heading-module--font_regular--c1ff2";
export var italic = "Heading-module--italic--0858b";
export var line_height_150 = "Heading-module--line_height_150--ccabf";
export var margin_bottom_1 = "Heading-module--margin_bottom_1--4d752";
export var margin_bottom_2 = "Heading-module--margin_bottom_2--0de47";
export var margin_bottom_3 = "Heading-module--margin_bottom_3--5ffba";
export var margin_bottom_4 = "Heading-module--margin_bottom_4--a0349";
export var margin_bottom_5 = "Heading-module--margin_bottom_5--998da";
export var margin_bottom_6 = "Heading-module--margin_bottom_6--6686d";
export var margin_bottom_7 = "Heading-module--margin_bottom_7--e6bfa";
export var margin_bottom_8 = "Heading-module--margin_bottom_8--96261";
export var margin_top_1 = "Heading-module--margin_top_1--17ddc";
export var margin_top_2 = "Heading-module--margin_top_2--f9668";
export var margin_top_3 = "Heading-module--margin_top_3--a238a";
export var margin_top_4 = "Heading-module--margin_top_4--79932";
export var margin_top_5 = "Heading-module--margin_top_5--22757";
export var margin_top_6 = "Heading-module--margin_top_6--0774a";
export var margin_top_7 = "Heading-module--margin_top_7--e84c4";
export var margin_top_8 = "Heading-module--margin_top_8--7e810";
export var removeLineBreakOnMobile = "Heading-module--removeLineBreakOnMobile--27e49";
export var responsive = "Heading-module--responsive--c2052";
export var root = "Heading-module--root--88ae8";
export var size_1 = "Heading-module--size_1--0e98f";
export var size_2 = "Heading-module--size_2--fffa8";
export var size_3 = "Heading-module--size_3--8bf76";
export var size_4 = "Heading-module--size_4--67f19";
export var size_5 = "Heading-module--size_5--34e83";
export var size_6 = "Heading-module--size_6--840d6";
export var size_7 = "Heading-module--size_7--e3a82";
export var size_8 = "Heading-module--size_8--481c6";
export var size_9 = "Heading-module--size_9--c0e05";
export var upperCase = "Heading-module--upperCase--67ac9";
export var weight_black = "Heading-module--weight_black--643fc";
export var weight_bold = "Heading-module--weight_bold--f12f7";
export var weight_extra_bold = "Heading-module--weight_extra_bold--16661";
export var weight_medium = "Heading-module--weight_medium--23cf3";
export var weight_regular = "Heading-module--weight_regular--aaa01";