/**
 * Layouts -> Header
 */

'use client';

import React, {
  memo,
  useRef,
  useState,
  useEffect,
  useMemo,
  // startTransition,
  useCallback,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useStaticQuery, graphql } from 'gatsby';
import { opacityLinkStyle } from '@styles/shared/link.module.css';
import useEventListener from '@utils/hooks/useEventListener';
import AppContext from '@layouts/AppContext';
import Button from '@components/Button';
import Container from '@components/Container';
import Link from '@components/Link';
import {
  isVisible as isVisibleClass,
  isHidden as isHiddenClass,
  proposalButton as proposalButtonClass,
  proposalButton__navigation as proposalButton__navigationClass,
  root as rootClass,
  navUp as navUpClass,
  navDown as navDownClass,
  container as containerClass,
  start as startClass,
  logo as logoClass,
  logo__img as logo__imgClass,
  mobile as mobileClass,
  desktop as desktopClass,
  middle as middleClass,
  end as endClass,
  hamburger as hamburgerClass,
  navigation as navigationClass,
  navigation__list as navigation__listClass,
  navigation__list__item as navigation__list__itemClass,
  onTop as onTopClass,
  navigation__overlay as navigation__overlayClass,
  sticky as stickyClass,
} from './Header.module.css';
import { Hamburger, NavItem } from './components';
import { ReactComponent as LogoMobile } from '@assets/images/logo/logo-abbreviated.svg';
import { ReactComponent as Logo } from '@assets/images/logo/logo.svg';

const SCROLL_DELTA = 5;

const Header = ({ location, renderButton }) => {
  const { toggleCtaModal } = useContext(AppContext);
  const lastRoute = useRef(null);
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [mobileNavHidden, setMobileNavHidden] = useState(true);
  const headerRef = useRef(null);
  const headerFocusTimeout = useRef(null);
  const [headerHasFocusWithin, setHeaderHasFocusWithin] = useState(false);
  const [sticky, setSticky] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const [hasScrolled, setHasScrolled] = useState(false);
  const previousScrollPosition = useRef(0);
  const shouldShowHeader = headerHasFocusWithin || (showHeader && hasScrolled);

  // Close menu on route change
  useEffect(() => {
    if (location.pathname !== lastRoute.current) {
      document.getElementById('gatsby-focus-wrapper').focus();
      setMobileNavOpen(false);
      setShowHeader(true);
      lastRoute.current = location.pathname;
    }
  }, [location.pathname]);

  const navigation = useStaticQuery(graphql`
    query MenuQuery {
      menu: wpMenu(
        name: { eq: "Navigation" }
        menuItems: { nodes: { elemMatch: { parentId: { eq: null } } } }
      ) {
        items: menuItems {
          nodes {
            parentId
            connectedNode {
              node {
                ... on WpPage {
                  title
                  uri
                  id
                  wpChildren {
                    nodes {
                      ... on WpPage {
                        title
                        uri
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  /**
   * Items
   * Note: We are filtering out the child items from the main array
   * because they for some odd reason is added both on the root array
   * and as children to parent menu item.
   */
  const items = useMemo(() => {
    const rootItems = (navigation?.menu?.items?.nodes || []).filter(
      (rootItem) => !rootItem.parentId,
    );

    return rootItems.map(({ connectedNode: { node: page } }) => ({
      label: page.title,
      path: page.uri,
      id: page.id,
      children: (page.wpChildren?.nodes ?? []).map((childPage) => ({
        label: childPage.title,
        path: childPage.uri,
        id: childPage.id,
      })),
    }));
  }, [navigation]);

  /**
   * When a child element is focused or blurred
   * we check if the focus is within the header and
   * show or hide the header accordingly
   */
  const onChildFocusOrBlur = () => {
    clearTimeout(headerFocusTimeout.current);
    headerFocusTimeout.current = setTimeout(() => {
      const hasFocusWithin = headerRef.current.contains(document.activeElement);
      setHeaderHasFocusWithin(hasFocusWithin);
    }, 50);
  };

  useEffect(() => {
    return () => {
      clearTimeout(headerFocusTimeout.current);
    };
  }, []);

  /**
   * Handle header visibility
   */
  const handleHeaderVisibility = () => {
    const outerHeightOfElement = (el) => {
      const style = getComputedStyle(el);
      return el.offsetHeight + parseInt(style.marginTop, 10) + parseInt(style.marginBottom, 10);
    };

    const shouldHideScrollPosition = outerHeightOfElement(headerRef.current) + 50;
    const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    const contentEndTopFromScreenTop = document
      .getElementById('s-footer')
      ?.getBoundingClientRect().top;

    // Scroll down
    if (
      currentScrollPosition > previousScrollPosition.current &&
      currentScrollPosition > shouldHideScrollPosition
    ) {
      const bottomReached =
        contentEndTopFromScreenTop <= window.innerHeight || contentEndTopFromScreenTop <= 0;

      if (bottomReached) {
        if (!showHeader) {
          setShowHeader(true);
        }

        // if bottom is not reached and header is visible
      } else if (showHeader) {
        setShowHeader(false);
      }

      // Scroll up
    } else if (!showHeader) {
      setShowHeader(true);
    }
  };

  const onScrollHandler = () => {
    // startTransition(() => {
    const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    const headerTop = document.getElementById('wrapper').getBoundingClientRect().top;
    const shouldStick = headerTop < -50;

    if (shouldStick && !sticky) {
      setSticky(true);
    } else if (!shouldStick && sticky) {
      setSticky(false);
    } else if (shouldStick && sticky) {
      if (Math.abs(previousScrollPosition.current - currentScrollPosition) >= SCROLL_DELTA) {
        // Only handle header visibility if the user has scrolled more than SCROLL_DELTA
        if (!hasScrolled) {
          setHasScrolled(true);
        }

        handleHeaderVisibility();
      }

      // Save previous scroll position
      previousScrollPosition.current = currentScrollPosition;
    }
    // });
  };

  useEventListener('scroll', onScrollHandler);
  // useEventListener('scroll', throttle(onScrollHandler, 10));

  /**
   * Mobile navigation
   */
  const toggleMobileMenu = () => {
    setMobileNavHidden(false);
    setTimeout(() => {
      setMobileNavOpen(!mobileNavOpen);
    }, 50);
  };

  const toggleNav = () => {
    if (mobileNavOpen) {
      setMobileNavOpen(false);
    }
  };

  useEffect(() => {
    let timeout;

    if (mobileNavOpen) {
      document.body.classList.add('mobile-nav-open');
    } else if (!mobileNavOpen && document.body.classList.contains('mobile-nav-open')) {
      document.body.classList.remove('mobile-nav-open');
    }

    if (mobileNavOpen) {
      clearTimeout(timeout);
      setMobileNavHidden(false);
    } else {
      timeout = setTimeout(() => {
        setMobileNavHidden(true);
      }, 300);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [mobileNavOpen]);

  const onNavItemClick = toggleNav;

  /**
   * Proposal button
   */
  const renderProposalButton = (placement) => {
    const onClick = () => {
      toggleNav();
      toggleCtaModal();
    };

    if (renderButton) {
      return typeof renderButton === 'function'
        ? renderButton({ placement, onClick })
        : renderButton;
    }

    return (
      <Button
        className={classnames(
          proposalButtonClass,
          placement === 'navigation' ? proposalButton__navigationClass : undefined,
        )}
        size={placement === 'center' ? 'small' : 'medium'}
        appearance="primary-outline"
        upperCase
        onClick={onClick}
      >
        Get in touch
      </Button>
    );
  };

  const renderMenuItem = useCallback((item) => {
    let childMenu = null;
    let attachSubMenuToItem = false;

    if (Array.isArray(item.children) && item.children.length) {
      attachSubMenuToItem = true;
      childMenu = [
        {
          title: null,
          pages: item.children.map((childItem) => ({
            label: childItem.label,
            uri: childItem.path,
            id: childItem.id,
          })),
        },
      ];
    }

    return (
      <NavItem
        key={item.id}
        label={item.label}
        path={item.path}
        id={item.id}
        onClick={onNavItemClick}
        onFocus={onChildFocusOrBlur}
        onBlur={onChildFocusOrBlur}
        childMenu={childMenu}
        attachSubMenuToItem={attachSubMenuToItem}
      />
    );
  }, []);

  // The header slides when scrolling downwards
  const isHidden = useMemo(
    () => (hasScrolled || headerHasFocusWithin) && !shouldShowHeader,
    [hasScrolled, headerHasFocusWithin, shouldShowHeader],
  );

  useEffect(() => {
    // Save the current header height in a CSS variable
    // This can be used to position sticky elements relative to the header
    document.documentElement.style.setProperty(
      '--current-site-header-height',
      `${headerRef.current.clientHeight}px`,
    );

    // Toggle a class when the header is visible which can be used in sub-components's CSS
    document.body.classList[isHidden ? 'add' : 'remove']('nav-hidden');
  }, [isHidden]);

  return (
    <header
      id="s-header"
      className={classnames(rootClass, {
        [navUpClass]: isHidden,
        [navDownClass]: !isHidden,
        [stickyClass]: sticky,
      })}
      ref={headerRef}
    >
      <Container className={containerClass}>
        <div className={startClass}>
          <Link
            shakeOnCurrentPage
            to="/"
            className={classnames(logoClass, opacityLinkStyle)}
            onFocus={onChildFocusOrBlur}
            onBlur={onChildFocusOrBlur}
            title="Go to the home page"
          >
            <LogoMobile className={classnames(logo__imgClass, mobileClass)} />
            <Logo className={classnames(logo__imgClass, desktopClass)} />
          </Link>
        </div>
        <div className={middleClass}>{renderProposalButton('center')}</div>
        <div className={endClass}>
          <Hamburger
            active={mobileNavOpen}
            onClick={toggleMobileMenu}
            className={hamburgerClass}
            aria-label="Toggle main navigation"
          />
          <nav
            className={classnames(
              navigationClass,
              { [isVisibleClass]: mobileNavOpen },
              { [isHiddenClass]: mobileNavHidden },
            )}
          >
            <ul className={navigation__listClass}>
              {items.map(renderMenuItem)}
              <li className={classnames(navigation__list__itemClass, onTopClass)}>
                {renderProposalButton('navigation')}
              </li>
            </ul>
          </nav>
          {/* eslint-disable jsx-a11y/no-static-element-interactions */}
          {/* eslint-disable jsx-a11y/click-events-have-key-events */}
          <div
            onClick={toggleMobileMenu}
            className={classnames(
              navigation__overlayClass,
              { [isVisibleClass]: mobileNavOpen },
              { [isHiddenClass]: mobileNavHidden },
            )}
          />
        </div>
      </Container>
    </header>
  );
};

Header.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  renderButton: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
};

export default memo(Header, (prev, next) => prev.location.pathname === next.location.pathname);
