/**
 * Container
 */

import React, { forwardRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { root, isRelative } from './Container.module.css';

const Container = forwardRef(
  ({ className, children, element: Element, relative, ...rest }, ref) => (
    <Element
      className={classnames(root, { [isRelative]: relative }, className)}
      {...rest}
      ref={ref}
    >
      {children}
    </Element>
  ),
);

Container.defaultProps = {
  element: 'div',
};

Container.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  element: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

export default Container;
