/**
 * Hooks -> useShake
 *
 * Shake an element!
 */

import { useEffect, useState, useRef } from 'react';

const useShake = (addDisplayInline = false) => {
  const shakeTimeout = useRef(null);
  const [isShaking, setIsShaking] = useState(false);
  const shake = () => (!isShaking ? setIsShaking(true) : undefined);

  useEffect(() => {
    let isMounted = true;

    if (isShaking) {
      shakeTimeout.current = setTimeout(() => {
        if (isMounted) {
          setIsShaking(false);
        }
      }, 800);
    }

    return () => {
      isMounted = false;

      if (shakeTimeout.current) {
        clearTimeout(shakeTimeout.current);
      }
    };
  }, [isShaking]);

  return {
    isShaking,
    className: isShaking ? `shake${addDisplayInline ? ' shake--block' : ''}` : '', // Global class
    shake,
  };
};

export default useShake;
