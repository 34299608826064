exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-portal-js": () => import("./../../../src/pages/portal.js" /* webpackChunkName: "component---src-pages-portal-js" */),
  "component---src-templates-contact-contact-js": () => import("./../../../src/templates/Contact/Contact.js" /* webpackChunkName: "component---src-templates-contact-contact-js" */),
  "component---src-templates-landing-page-landing-page-js": () => import("./../../../src/templates/LandingPage/LandingPage.js" /* webpackChunkName: "component---src-templates-landing-page-landing-page-js" */),
  "component---src-templates-page-page-js": () => import("./../../../src/templates/Page/Page.js" /* webpackChunkName: "component---src-templates-page-page-js" */),
  "component---src-templates-project-project-js": () => import("./../../../src/templates/Project/Project.js" /* webpackChunkName: "component---src-templates-project-project-js" */),
  "component---src-templates-projects-projects-js": () => import("./../../../src/templates/Projects/Projects.js" /* webpackChunkName: "component---src-templates-projects-projects-js" */)
}

