/**
 * useUserHasInteracted
 *
 * Hook for determining if a user has interacted with the page
 */

// import { startTransition } from 'react';
import AppContext from '@layouts/AppContext';
import { useContext, useEffect, useRef } from 'react';

const useUserHasInteracted = (timer = null) => {
  const timeout = useRef(null);
  const { userDidInteract, setUserDidInteract } = useContext(AppContext);

  /**
   * Set interacted after a timer of x ms
   */
  useEffect(() => {
    if (typeof timer === 'number' && !userDidInteract) {
      timeout.current = setTimeout(() => {
        // startTransition(() => {
        setUserDidInteract(true);
        // });
      }, timer);
    } else if (timeout.current) {
      clearTimeout(timeout.current);
    }

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [userDidInteract]);

  return userDidInteract;
};

export default useUserHasInteracted;
