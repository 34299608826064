/**
 * Icon
 */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as css from './Icon.module.css';

const Icon = forwardRef(
  (
    {
      children,
      className,
      icon,
      iconClassName,
      iconPosition,
      iconStyle,
      id,
      labelClassName,
      rotate90,
      size,
      spin,
      ...rest
    },
    ref,
  ) => {
    const getRootClass = classnames(
      css.root,
      className,
      {
        [css[`icon_position_${iconPosition}`]]: children && iconPosition,
      },
      { [css.spin]: spin },
    );

    const getSVGClass = classnames(css.icon, css[size], iconClassName);

    // Defaults to the default-icon
    let IconElement = (props) => (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
        <path d="M16 30.4c7.8 0 14.4-6.4 14.4-14.4S23.8 1.6 16 1.6 1.6 8.2 1.6 16 8.2 30.4 16 30.4zm0-24.8c5.6 0 10.4 4.6 10.4 10.4S21.8 26.4 16 26.4 5.6 21.6 5.6 16 10.4 5.6 16 5.6z" />
      </svg>
    );

    // Custom icon
    if (typeof icon === 'function') {
      IconElement = icon;
    }

    return (
      <span
        className={classnames(getRootClass, { [css.rotate90]: rotate90 })}
        tabIndex="-1"
        id={id}
        {...rest}
        ref={ref}
      >
        <IconElement className={getSVGClass} style={iconStyle} focusable="false" />
        {children ? (
          <span className={classnames(css.label, labelClassName)}>{children}</span>
        ) : null}
      </span>
    );
  },
);

Icon.propTypes = {
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.func,
  iconPosition: PropTypes.oneOf(['start', 'end']),
  id: PropTypes.string,
  labelClassName: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  iconStyle: PropTypes.object,
  rotate90: PropTypes.bool,
  spin: PropTypes.bool,
};

Icon.defaultProps = {
  size: 'medium',
  iconPosition: 'start',
  icon: 'default',
  iconStyle: {},
  rotate90: false,
};

Icon.displayName = 'Icon';

export default Icon;
