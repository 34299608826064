/**
 * Layouts -> Main
 */

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as css from './Main.module.css';

const Main = ({ children, className, ...rest }) => (
  <main className={classnames(css.root, className)} {...rest}>
    {children}
  </main>
);

Main.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Main;
