/**
 * CTAOverlay (lazy loaded)
 */

import React, { lazy, Suspense } from 'react';
import useUserHasInteracted from '@utils/hooks/useUserHasInteracted';

const LazyCTAOverlay = lazy(() => import('./CTAOverlay'));

const CTAOverlay = (props) => {
  const userDidInteract = useUserHasInteracted();

  if (!userDidInteract) {
    return null;
  }

  return (
    <Suspense fallback={null}>
      <LazyCTAOverlay {...props} />
    </Suspense>
  );
};

export default CTAOverlay;
