/**
 * Components -> ContactInfo
 */

import React, { memo } from 'react';
import Icon from '@components/Icon';
import useSiteData from '@utils/hooks/useSiteData';
import { ReactComponent as Email } from '@components/Icon/icons/mail.svg';
import { ReactComponent as Phone } from '@components/Icon/icons/phone.svg';
import { ReactComponent as House } from '@components/Icon/icons/house.svg';
import { root, text, icon as iconClass } from './ContactInfo.module.css';
import classNames from 'classnames';

const ContactInfo = ({
  className,
  textBelow,
  email,
  address,
  phone,
  googleMapsLink,
  googleMapsLinkTitle,
}) => {
  const site = useSiteData();

  const info = {
    phone: {
      value: typeof phone === 'string' ? phone : site.phone,
      icon: Phone,
    },
    email: {
      value: typeof email === 'string' ? email : site.email,
      icon: Email,
    },
    address: {
      value: typeof address === 'string' ? address : site.address,
      icon: House,
    },
  };

  const renderItem = (type) => {
    const { value, icon } = info[type];

    if (!value) {
      return null;
    }

    let href = '';
    let title = '';

    if (type === 'email') {
      href = `mailto:${value.trim()}`;
      title = 'Send us an email';
    }

    if (type === 'phone') {
      href = `tel:${value.trim()}`;
      title = 'Call us';
    }

    if (type === 'address') {
      href = googleMapsLink ?? site.googleMapsLink;
      title = googleMapsLinkTitle ?? 'View our address on Google Maps';
    }

    return (
      <li key={href}>
        <a href={href} target="_blank" rel="noreferrer" title={title}>
          <Icon className={iconClass} icon={icon} />
          <div className={text} dangerouslySetInnerHTML={{ __html: value }} />
        </a>
      </li>
    );
  };

  return (
    <nav className={classNames(root, className)}>
      <ul>{Object.keys(info).map(renderItem)}</ul>
      {textBelow && <div className={text}>{textBelow}</div>}
    </nav>
  );
};

export default memo(ContactInfo, () => true);
