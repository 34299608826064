/**
 * getPreloadableLazyComponent
 *
 * Adds a preload method for components rendered with <Suspense>
 * Usage:
 *
 * const LazyLoadedComponent = getPreloadableLazyComponent(() => import('./LazyLoadedComponent'));
 *
 * In useEffect or callback: LazyLoadedComponent.preload();
 *
 * <Suspense fallback={null}>
 * <LazyLoadedComponent />
 * </Suspense>
 */

import { lazy } from 'react';

const getPreloadableLazyComponent = (dynamicImportFactory) => {
  const Component = lazy(dynamicImportFactory);
  Component.preload = dynamicImportFactory;
  return Component;
};

export default getPreloadableLazyComponent;
