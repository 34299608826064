// extracted by mini-css-extract-plugin
export var appearance_icon = "Button-module--appearance_icon--32e72";
export var appearance_icon_light = "Button-module--appearance_icon_light--bf7c2";
export var appearance_link = "Button-module--appearance_link--03f42";
export var appearance_primary = "Button-module--appearance_primary--7c32c";
export var appearance_primary_outline = "Button-module--appearance_primary_outline--c2db3";
export var appearance_select = "Button-module--appearance_select--82abb";
export var appearance_select_active = "Button-module--appearance_select_active--0ca42";
export var arrow = "Button-module--arrow--1d1aa";
export var block = "Button-module--block--a1fe3";
export var hasArrow = "Button-module--hasArrow--6cee0";
export var root = "Button-module--root--c1f31";
export var size_inherit = "Button-module--size_inherit--714ed";
export var size_large = "Button-module--size_large--e99d4";
export var size_medium = "Button-module--size_medium--04b3d";
export var size_small = "Button-module--size_small--cd324";
export var upperCase = "Button-module--upperCase--be36b";