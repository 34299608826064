/**
 * Hooks -> useScrollToUrlHash
 *
 * A helper hook for automatically scrolling the an element
 * with the ID corresponding to the current URL hash
 */

import { useEffect, useRef } from 'react';
import scrollToElement from '@utils/dom/scrollToElement';

const useScrollToUrlHash = () => {
  const timeout = useRef(null);

  useEffect(() => {
    let isMounted = true;

    if (window.location.href.includes('#')) {
      const { href } = window.location;
      const hashValue = href.slice(href.indexOf('#') + 1, href.length);

      if (hashValue) {
        const el = document.getElementById(hashValue);
        if (el) {
          timeout.current = setTimeout(() => {
            if (isMounted) {
              scrollToElement(el);
            }
          }, 200);
        }
      }
    }

    return () => {
      isMounted = false;
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);
};

export default useScrollToUrlHash;
