/**
 * Button
 */

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ReactComponent as arrowRight } from '@components/Icon/icons/arrow-right.svg';
import Icon from '@components/Icon';
import * as css from './Button.module.css';

const Button = forwardRef(
  (
    {
      appearance,
      arrow,
      block,
      children,
      className,
      element: Element,
      size,
      type,
      upperCase,
      ...rest
    },
    ref,
  ) => (
    <Element
      type={type}
      className={classnames(
        css.root,
        { [css[`size_${size}`]]: size },
        { [css[`appearance_${appearance.replace('-', '_')}`]]: appearance },
        { [css.upperCase]: upperCase },
        { [css.block]: block },
        { [css.hasArrow]: arrow },
        className,
      )}
      {...rest}
      ref={ref}
    >
      {children}
      {arrow && <Icon iconPosition="end" icon={arrowRight} className={css.arrow} />}
    </Element>
  ),
);

export const propTypes = {
  appearance: PropTypes.oneOf([
    'plain',
    'icon',
    'icon-light',
    'link',
    'primary-outline',
    'primary',
    'secondary',
    'select-active',
    'select',
  ]),
  arrow: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  element: PropTypes.any,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'inherit']),
  type: PropTypes.oneOf(['submit', 'button']),
  upperCase: PropTypes.bool,
  block: PropTypes.bool,
};

Button.displayName = 'Button';
Button.propTypes = propTypes;
Button.defaultProps = {
  type: 'button',
  element: 'button',
  size: 'medium',
  appearance: 'primary',
  upperCase: false,
};

export default Button;
