/**
 * Hamburger
 */

import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import * as css from './Hamburger.module.css';

const Hamburger = ({ active, onClick, id, className, ...rest }) => (
  <button
    onClick={onClick}
    type="button"
    className={classnames(
      css.hamburger,
      css.hamburger__collapse,
      { [css.is_active]: active },
      className,
    )}
    aria-label="Toggle menu"
    aria-expanded={active}
    id={id}
    {...rest}
  >
    <span className={css.hamburger_box}>
      <span className={css.hamburger_inner} />
    </span>
  </button>
);

Hamburger.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
};

export default Hamburger;
