/**
 * Layout
 */

'use client';

import React, { useEffect, useState, useRef, useReducer, Suspense } from 'react';
import PropTypes from 'prop-types';
import AppContext from './AppContext';
import CookieBanner from './components/CookieBanner';
import Header from './components/Header';
import Footer from './components/Footer';
import Main from './components/Main';
import Wrap from './components/Wrap';
import CTAOverlay from './components/CTAOverlay';
import ProgressBar from './components/ProgressBar';
import useScrollToUrlHash from '@utils/hooks/useScrollToUrlHash';
import getPreloadableLazyComponent from '../utils/lazy/getPreloadableLazyComponent';

const EVENTS = ['touchstart', 'click', 'scroll', 'mousemove'];

const Layout = ({ children, location, pageContext }) => {
  useScrollToUrlHash();

  const wrapRef = useRef(null);
  const eventListenersRemoved = useRef(null);
  const prevPathname = useRef(location.pathname);
  const { template } = pageContext || {};
  const [ready, setIsReady] = useState(false);
  const [userDidInteract, setUserDidInteract] = useState(false);

  const [openCtaOverlay, toggleCtaOverlay] = useReducer((curr) => !curr, false);
  const handleToggleCtaOverlay = () => {
    getPreloadableLazyComponent(() =>
      import('@layouts/components/CTAOverlay/components/ContactForm'),
    ).preload();

    setUserDidInteract(true);
    toggleCtaOverlay();
  };

  const [progressState, setProgressState] = useState({
    loaded: false,
    key: null,
    isAnimating: false,
  });

  useEffect(() => {
    setIsReady(true);

    /**
     * Check when user has interacted with the page
     * This is used for lazy loading some parts of the UI
     */
    const onInteraction = () => {
      EVENTS.forEach((event) => {
        document.removeEventListener(event, onInteraction);
      });

      // startTransition(() => {
      setUserDidInteract(true);
      // });

      eventListenersRemoved.current = true;
    };

    // Setup event listeners
    EVENTS.forEach((event) => {
      document.addEventListener(event, onInteraction);
    });

    return () => {
      if (!eventListenersRemoved.current) {
        EVENTS.forEach((event) => {
          document.removeEventListener(event, onInteraction);
        });
      }
    };
  }, []);

  /**
   * Progress Bar
   */
  const startProgressBar = (key) => {
    setProgressState((curr) => ({ ...curr, loaded: true, key, isAnimating: true }));
  };

  const stopProgressBar = () => {
    setProgressState((curr) => ({ ...curr, isAnimating: false }));
  };

  // Start progress bar if clicked URL is different than current
  const progressTimeout = useRef(null);
  const startPageProgressBar = (currPath) => {
    if (currPath !== location.pathname) {
      if (progressTimeout.current) {
        clearTimeout(progressTimeout.current);
      }

      // Set a small timeout to prevent loading bar from appearing
      // if the page transition is too fast
      progressState.current = setTimeout(() => {
        startProgressBar(currPath);
      }, 25);
    }
  };

  // Stop progress bar on page change
  useEffect(() => {
    let isMounted = true;

    if (prevPathname.current !== location.pathname) {
      if (progressState.current) {
        clearTimeout(progressState.current);
      }

      if (isMounted) {
        stopProgressBar(false);
      }
    }
    prevPathname.current = location.pathname;

    return () => {
      isMounted = false;
    };
  }, [location.pathname]);

  /**
   * Global app context
   */
  const contextValue = {
    animatingProgress: progressState.isAnimating,
    startProgressBar,
    stopProgressBar,
    startPageProgressBar,
    userDidInteract,
    setUserDidInteract,
    ready,
    toggleCtaModal: handleToggleCtaOverlay,
  };

  return (
    <Wrap hasPaddingTop template={template} ref={wrapRef}>
      <AppContext.Provider value={contextValue}>
        <CTAOverlay onToggle={handleToggleCtaOverlay} open={openCtaOverlay} />
        <ProgressBar
          isAnimating={progressState.isAnimating}
          key={progressState.key}
          show={progressState.loaded}
        />
        <Header location={location} />
        <Suspense fallback={null}>
          <Main>{children}</Main>
          <Footer location={location} template={template} />
        </Suspense>
        <CookieBanner />
      </AppContext.Provider>
    </Wrap>
  );
};

Layout.propTypes = {
  location: PropTypes.shape({
    href: PropTypes.string,
    pathname: PropTypes.string,
  }),
  data: PropTypes.shape({
    page: PropTypes.shape({
      seo: PropTypes.object,
      featuredImage: PropTypes.object,
    }),
  }),
};

Layout.propTypes = {
  children: PropTypes.node,
  pageContext: PropTypes.shape({
    template: PropTypes.string,
  }),
};

export default Layout;
