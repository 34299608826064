/**
 * Components -> TextFormatting
 *
 * Applies the global CSS for the TinyMCE text content from Wordpress
 */

import React, { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './TextFormatting.css';

const TextFormatting = forwardRef(
  ({ className, element: Element, children, content, enableReplacements = true }, ref) => {
    // const withReplacements = useMemo(() => {
    //   if (typeof content !== 'string') {
    //     return '';
    //   }

    //   let replaced = content;

    //   // @temp-url-fix: Temporary fix for missing WP urls in inline images etc.
    //   replaced = replaceString(
    //     replaced,
    //     '/wp-content/uploads/',
    //     `${process.env.GATSBY_WORDPRESS_URL}/wp-content/uploads/`,
    //   );

    //   // replaced = replaceString(replaced, '/gifs/', `${process.env.GATSBY_WORDPRESS_URL}/gifs/`);

    //   return replaced;
    // }, [content]);
    const withReplacements = content;

    return (
      <Element
        ref={ref}
        className={classnames('text-formatting', className)}
        dangerouslySetInnerHTML={
          content && !children
            ? { __html: enableReplacements ? withReplacements : content }
            : undefined
        }
      >
        {children}
      </Element>
    );
  },
);

TextFormatting.defaultProps = {
  element: 'div',
};

TextFormatting.propTypes = {
  className: PropTypes.string,
  content: PropTypes.string,
  element: PropTypes.any,
  enableReplacements: PropTypes.bool,
};

export default TextFormatting;
