/**
 * ProgressBar (lazy loaded)
 */

'use client';

import React, { lazy, Suspense, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const ProgressBar = lazy(() => import('./ProgressBar'));

const LazyProgressBar = ({ isAnimating, show }) => {
  const [rendered, setRendered] = useState(false);

  useEffect(() => {
    if (!rendered && show) {
      setRendered(true);
    }
  }, [show]);

  if (!rendered) {
    return null;
  }

  return (
    <Suspense fallback={null}>
      <ProgressBar isAnimating={isAnimating} />
    </Suspense>
  );
};

LazyProgressBar.propTypes = {
  isAnimating: PropTypes.bool,
  show: PropTypes.bool,
};

export default LazyProgressBar;
